<template>
    <a-modal
        v-model:visible="visible"
        :title="`图片上传(${curPicIds?.length}/12)`"
        width="720px"
        :destroyOnClose="true"
        :footer="null"
        @cancel="handleCancel"
    >
        <Upload
            businessType="REPORT_WORK_LIVE_PICTURE"
            @onSuccess="ids => onSuccess(ids)"
            :defaultPics="
                curPicIds?.map(item => ({
                    id:item,
                    status: 'done',
                    url: `${baseURL}/web/mes-file-info/download/${item}`,
                }))
            "
        />
    </a-modal>
</template>
<script>
import { defineComponent, reactive, ref, toRefs, watch } from "vue";
import Upload from "@/components/Upload";
import { baseURL } from "@/utils/config";
export default defineComponent({
    props: {
        curPicIds: {
            default: null,
        },
    },
    components: {
        Upload,
    },
    emits: ["uploadPicSuccess", "onClose"],
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
        });

        const handleCancel = () => {
            state.visible = false;
            emit("onClose");
        };

        const onSuccess = ids => {
            emit("uploadPicSuccess", ids);
        };

        watch(
            () => props?.curPicIds,
            newVal => {
                if (newVal != null) {
                    state.visible = true;
                } else {
                    state.visible = false;
                }
            }
        );

        return {
            ...toRefs(state),
            onSuccess,
            handleCancel,
            baseURL
        };
    },
});
</script>
