<template>
    <DeviceSelect
        v-model:deviceSelectVisible="deviceSelectVisible"
        :disableData="tableData"
        @onSelect="onSelectDevice"
    />
    <a-modal
        v-model:visible="visible"
        title="设备使用信息"
        width="720px"
        :destroyOnClose="true"
        @cancel="handleCancel"
        @ok="handleOk"
    >
        <div class="search">
            <a-button type="primary" size="small" @click="deviceSelectVisible = true">
                添加
            </a-button>
        </div>
        <a-table :dataSource="tableData" :columns="columns" bordered size="small">
            <template #bodyCell="{ column, record, index }">
                <template v-if="column.dataIndex === 'index'">
                    {{ record.index + 1 }}
                </template>
                <template v-if="column.dataIndex === 'operate'">
                    <a @click="handleDelete(record.index)">删除</a>
                </template>
            </template>
        </a-table>
    </a-modal>
</template>
<script>
import { computed, defineComponent, reactive, ref, toRefs, watch } from "vue";
import DeviceSelect from "@/components/DeviceList";
export default {
    props: {
        curDevices: {
            default: null,
        },
    },
    components: {
        DeviceSelect,
    },
    emits: ["onCloseDeviceList"],
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            deviceSelectVisible: false,
            tableData: [],
        });

        const handleDelete = index => {
            state.tableData.splice(index, 1);
            state.tableData = state.tableData.map((item, index) => ({
                ...item,
                index,
            }));
        };

        const handleOk = () => {
            emit("onSelectDeviceOk", state.tableData);
            handleCancel();
        };

        const handleCancel = () => {
            state.visible = false;
            state.tableData = [];
            emit("onClose");
        };

        const onSelectDevice = value => {
            state.tableData = [...state.tableData, ...value].map((item, index) => ({
                ...item,
                index,
            }));
        };

        watch(
            () => props?.curDevices,
            newVal => {
                if (newVal != null) {
                    state.visible = true;
                    state.tableData = newVal;
                } else {
                    state.visible = false;
                }
            }
        );

        return {
            columns: [
                {
                    title: "序号",
                    dataIndex: "index",
                    width: 60,
                    fixed: "left",
                },
                {
                    title: "设备编码",
                    dataIndex: "code",
                },
                {
                    title: "设备名称",
                    dataIndex: "name",
                },
                {
                    title: "设备类别",
                    dataIndex: "categoryName",
                },
                {
                    title: "规格型号",
                    dataIndex: "spec",
                },
                {
                    title: "操作",
                    dataIndex: "operate",
                    fixed: "right",
                    width: 100,
                },
            ],
            ...toRefs(state),
            handleDelete,
            handleCancel,
            onSelectDevice,
            handleOk,
        };
    },
};
</script>

<style lang="less" scoped>
.search {
    text-align: right;
    margin-bottom: 10px;
}
</style>
