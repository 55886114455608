<template>
    <a-table
        :columns="columns"
        :data-source="data.sort((a, b) => a.index - b.index)"
        bordered
        size="small"
        :pagination="false"
    >
        <template #bodyCell="{ column, record, index }">
            <template v-if="column.dataIndex === 'index'">
                {{ index + 1 }}
            </template>
            <template v-if="column.dataIndex === 'statusDesc'">
                <span :style="{ color: record?.status == 'PROCESSED' ? '#2BCE1D' : '#ff4d4f' }">
                    {{ record?.statusDesc }}
                </span>
            </template>
            <template v-if="column.dataIndex === 'eventPicIds'">
                <a
                    v-if="record?.eventPicIds?.length"
                    @click="handleViewPic(record.id, record?.eventPicIds)"
                >
                    查看({{ record?.eventPicIds?.length }})
                </a>
                <span v-else style="color: rgba(0, 0, 0, 0.3)">暂无图片</span>
                <div style="display: none" v-if="previewId == record?.id">
                    <a-image-preview-group
                        :preview="{
                            visible: Boolean(previewId == record?.id),
                            onVisibleChange: vis => (previewId = null),
                        }"
                    >
                        <a-image
                            v-for="item in record?.eventPicIds"
                            :key="item"
                            :src="`${fileURL}/download/${item}`"
                        />
                    </a-image-preview-group>
                </div>
            </template>
        </template>
    </a-table>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import { fileURL } from "@/utils/config";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 60,
    },
    {
        title: "事件单号",
        dataIndex: "code",
    },
    {
        title: "事件名称",
        dataIndex: "eventName",
    },
    {
        title: "事件状态",
        dataIndex: "statusDesc",
    },
    {
        title: "异常类型",
        dataIndex: "excType",
    },
    {
        title: "问题描述",
        dataIndex: "description",
    },
    {
        title: "上报人",
        dataIndex: "creatorDisplayName",
    },
    {
        title: "事件图片",
        dataIndex: "eventPicIds",
    },
    {
        title: "提交时间",
        dataIndex: "createdTime",
    },
];
export default defineComponent({
    props: {
        data: {
            default: [],
        },
        routeInfo: {
            default: null,
        },
    },
    setup() {
        const state = reactive({
            previewId: null,
        });

        const handleViewPic = (id, pics) => {
            if (!pics.length) return showMessage("info", "暂无图片");
            state.previewId = id;
        };

        return {
            columns,
            ...toRefs(state),
            handleViewPic,
            fileURL,
        };
    },
});
</script>
