<template>
    <UserList
        v-model:userListVisible="userListVisible"
        @onSelect="onSelectCheckUser"
        selectType="radio"
    />
    <a-modal
        v-model:visible="visible"
        title="工序返工"
        @cancel="handleCancel"
        :footer="null"
        :destroyOnClose="true"
        width="750px"
    >
        <a-form
            ref="formRef"
            :model="formData"
            name="basic"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 17 }"
            autocomplete="off"
            @finish="onFinish"
        >
            <a-form-item label="返工工序" name="workOrderStepId">
                <a-input :value="reworkRow?.name" disabled />
            </a-form-item>

            <a-form-item
                label="返工类型"
                name="type"
                :rules="[{ required: true, message: '请选择返工类型' }]"
            >
                <a-radio-group v-model:value="formData.type">
                    <a-radio value="INDEPENDENT">独立返工</a-radio>
                    <a-radio value="ASSOCIATED">关联返工</a-radio>
                </a-radio-group>
            </a-form-item>

            <a-form-item
                label="返工原因"
                name="reason"
                :rules="[
                    { required: true, message: '请输入返工原因' },
                    { validator: checkSpaceStartEnd },
                ]"
            >
                <a-textarea
                    v-model:value="formData.reason"
                    placeholder="最多可输入800字"
                    :rows="4"
                    :maxlength="800"
                />
            </a-form-item>

            <!-- <a-form-item
                label="检验员"
                name="checkerDisplayName"
                :rules="[{ required: true, message: '请选择检验员' }]"
            >
                <div class="contant-item">
                    <a-input style="width: 320px" v-model:value="formData.checkerDisplayName">
                        <template #suffix>
                            <down-outlined style="color: rgba(0, 0, 0, 0.25)" />
                        </template>
                    </a-input>
                    <div class="mask" @click="userListVisible = true"></div>
                </div>
            </a-form-item>

            <a-form-item
                label="检验员签名"
                name="checkerSignaturePictureId"
                :rules="[{ required: true, message: '请上传签名' }]"
            >
                <Upload
                    :maxCount="1"
                    businessType="work_order_step_rework_sugn_pic"
                    @onSuccess="ids => (formData.checkerSignaturePictureId = ids[0])"
                />
            </a-form-item> -->

            <a-form-item :wrapper-col="{ offset: 18, span: 5 }">
                <a-button style="margin-right: 8px" @click="handleCancel">取消</a-button>
                <a-button type="primary" html-type="submit">提交</a-button>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs } from "vue";
import { apiWorkOrderSaveProcessRework } from "@/api";
import Select from "@/components/Select";
import { showMessage, checkValueIsAvailable } from "@/utils/common";
import { DownOutlined } from "@ant-design/icons-vue";
import Upload from "@/components/Upload";
import UserList from "@/components/UserList";
export default defineComponent({
    props: ["reworkRow"],
    emits: ["eventUpdateOk", "update:reworkRow"],
    components: {
        Select,
        DownOutlined,
        Upload,
        UserList,
    },
    setup(props, { emit }) {
        const state = reactive({
            formRef: null,
            visible: false,
            userListVisible: false,
            formData: {
                workOrderStepId: "",
                type: "",
                reason: "",
                // checkerUsername: "",
                // checkerDisplayName: "",
                // checkerSignaturePictureId: "",
            },
        });

        const handleCancel = () => {
            state.visible = false;
            state.formRef.resetFields();
            emit("update:reworkRow", null);
        };

        const onSelectCheckUser = ([user]) => {
            state.formData.checkerUsername = user?.username;
            state.formData.checkerDisplayName = user?.displayName;
        };

        const onFinish = async () => {
            let res = await apiWorkOrderSaveProcessRework(state.formData);
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                handleCancel();
                emit("eventUpdateOk");
            }
        };

        const checkSpaceStartEnd = (rule, value) => {
            if (value && !checkValueIsAvailable(value, "spaceStartEnd")) {
                return Promise.reject("不能以空格开头或结尾");
            } else {
                return Promise.resolve();
            }
        };

        watch(
            () => props.reworkRow,
            newVal => {
                if (newVal !== null) {
                    state.visible = true;
                    state.formData.workOrderStepId = newVal?.id;
                } else {
                    state.visible = false;
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            onFinish,
            checkSpaceStartEnd,
            onSelectCheckUser,
        };
    },
});
</script>

<style lang="less" scoped>
.report-process-desc {
    display: flex;
    color: grey;
    margin-top: 10px;
    > div {
        flex: 1;
    }
}

.contant-item {
    display: flex;
    justify-content: space-between;
}
.mask {
    position: absolute;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 32px;
    height: 32px;
    border-radius: 2px;
    width: 320px;
    cursor: pointer;
    position: absolute;
    opacity: 0;
}
</style>
