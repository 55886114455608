<template>
    <a-modal
        v-model:visible="visible"
        title="异常信息"
        width="720px"
        :destroyOnClose="true"
        :footer="null"
        @cancel="handleCancel"
    >
        <a-form
            ref="formRef"
            :model="formData"
            name="basic"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 17 }"
            :validateTrigger="['onBlur', 'change']"
            autocomplete="off"
            @finish="onFinish"
        >
            <a-form-item
                label="异常情况"
                name="exception"
                :rules="[{ required: true, message: '请选择异常情况' }]"
            >
                <a-radio-group v-model:value="formData.exception">
                    <a-radio :value="true">是</a-radio>
                    <a-radio :value="false">否</a-radio>
                </a-radio-group>
            </a-form-item>

            <div v-show="formData.exception">
                <a-form-item
                    label="异常时长"
                    name="exceptionWorkingHours"
                    :rules="[
                        { required: formData.exception, message: '请输入异常时长' },
                        { validator: checkExceptionWorkingHours },
                    ]"
                >
                    <a-input-number
                        v-model:value="formData.exceptionWorkingHours"
                        :disabled="!formData.exception"
                        :min="0"
                        :precision="2"
                        style="width: 100%"
                    />
                </a-form-item>

                <a-form-item label="异常总工时" name="productTime">
                    <span style="margin-left: 8px">
                        {{
                            (
                                formData.exceptionWorkingHours *
                                (formData.attendances?.length + formData.hourlyWorkerCount)
                            ).toFixed(2)
                        }}
                        / H
                    </span>
                </a-form-item>

                <a-form-item
                    label="异常事件名称"
                    name="exceptionMatter"
                    :rules="[
                        { required: formData.exception, message: '请输入异常事件名称' },
                        { validator: checkSpaceAll },
                    ]"
                >
                    <a-input
                        v-model:value="formData.exceptionMatter"
                        :disabled="!formData.exception"
                        :maxlength="100"
                        showCount
                    />
                </a-form-item>

                <a-form-item
                    label="异常类型"
                    name="excType"
                    :rules="[{ required: formData.exception, message: '请选择异常类型' }]"
                >
                    <Select
                        v-model:value="formData.excType"
                        :options="excTypeOptions"
                        :disabled="!formData.exception"
                    />
                </a-form-item>

                <a-form-item
                    label="异常描述"
                    name="exceptionDescription"
                    :rules="[{ validator: checkSpaceAll }]"
                >
                    <a-textarea
                        v-model:value="formData.exceptionDescription"
                        placeholder="最多可输入800字"
                        :disabled="!formData.exception"
                        :rows="4"
                        :maxlength="800"
                    />
                </a-form-item>

                <a-form-item name="exceptionEventFileIds">
                    <template v-slot:label>
                        事件图片({{ formData.exceptionEventFileIds.length }}/12)
                    </template>
                    <Upload
                        businessType="mes_exc_report_event_pic"
                        :disabled="!formData.exception"
                        :defaultPics="
                            formData.exceptionEventFileIds?.map(item => ({
                                id: item,
                                status: 'done',
                                url: `${baseURL}/web/mes-file-info/download/${item}`,
                            }))
                        "
                        @onSuccess="ids => (formData.exceptionEventFileIds = ids)"
                    />
                </a-form-item>

                <a-form-item
                    label="处理状态"
                    name="exceptionProcessStatus"
                    :rules="[{ required: formData.exception, message: '请选择处理状态' }]"
                >
                    <a-radio-group
                        v-model:value="formData.exceptionProcessStatus"
                        :disabled="!formData.exception"
                    >
                        <a-radio value="PROCESSED">已处理</a-radio>
                        <a-radio value="WAIT_PROCESSED">未处理</a-radio>
                    </a-radio-group>
                </a-form-item>

                <a-form-item
                    label="处理方案"
                    name="exceptionProcessingScheme"
                    v-show="comExceptionProcessStatus"
                    :rules="[
                        {
                            required: comExceptionProcessStatus,
                            message: '请输入处理方案',
                        },
                        { validator: checkSpaceAll },
                    ]"
                >
                    <a-textarea
                        v-model:value="formData.exceptionProcessingScheme"
                        placeholder="最多可输入800字"
                        :disabled="!formData.exception"
                        :rows="4"
                        :maxlength="800"
                    />
                </a-form-item>

                <a-form-item name="exceptionEventHandleFileIds" v-show="comExceptionProcessStatus">
                    <template v-slot:label>
                        处理方案图片({{ formData.exceptionEventHandleFileIds.length }}/12)
                    </template>
                    <Upload
                        businessType="mes_exc_report_handle_pic"
                        :defaultPics="
                            formData.exceptionEventHandleFileIds?.map(item => ({
                                id: item,
                                status: 'done',
                                url: `${baseURL}/web/mes-file-info/download/${item}`,
                            }))
                        "
                        @onSuccess="ids => (formData.exceptionEventHandleFileIds = ids)"
                    />
                </a-form-item>
            </div>

            <a-form-item :wrapper-col="{ offset: 18, span: 5 }">
                <a-button style="margin-right: 8px" @click="handleCancel">取消</a-button>
                <a-button type="primary" html-type="submit">提交</a-button>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
import { computed, defineComponent, reactive, ref, toRefs, watch } from "vue";
import Upload from "@/components/Upload";
import Select from "@/components/Select";
import moment from "moment";
import { baseURL } from "@/utils/config";
export default {
    props: {
        curException: {
            default: null,
        },
        excTypeOptions: {
            default: [],
        },
    },
    components: {
        Select,
        Upload,
    },
    emits: ["onClose", "onChange"],
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            formData: {
                exception: false,
                productTime: "",
                exceptionWorkingHours: "",
                exceptionMatter: "",
                excType: "",
                exceptionDescription: "",
                exceptionEventFileIds: [],
                exceptionProcessStatus: null,
                exceptionProcessingScheme: "",
                exceptionEventHandleFileIds: [],
            },
            comExceptionProcessStatus: computed(
                () => state.formData.exceptionProcessStatus == "PROCESSED"
            ),
        });

        const handleCancel = () => {
            state.visible = false;
            emit("onClose");
        };

        const onFinish = () => {
            emit("onChange", state.formData);
            handleCancel();
        };

        const checkExceptionWorkingHours = (rule, value) => {
            const { productTime, exception } = state.formData;
            if (productTime?.length && exception) {
                const [sTime, eTime] = productTime;
                let totalMinutes = moment(eTime, "hh:mm").diff(moment(sTime, "hh:mm"), "minute");
                if (value * 60 > totalMinutes) {
                    return Promise.reject("异常时长不能大于生产时长");
                }
            }
            return Promise.resolve();
        };

        watch(
            () => props?.curException,
            newVal => {
                if (newVal != null) {
                    state.visible = true;
                    state.formData = {
                        ...newVal,
                    };
                } else {
                    state.visible = false;
                }
            }
        );

        return {
            ...toRefs(state),
            checkExceptionWorkingHours,
            handleCancel,
            onFinish,
            baseURL,
        };
    },
};
</script>

<style lang="less" scoped>
.search {
    text-align: right;
    margin-bottom: 10px;
}
</style>
