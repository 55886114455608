<template>
    <LoadPic
        :curPicIds="curPicIds"
        @uploadPicSuccess="ids => uploadPicSuccess(ids)"
        @onClose="onClose"
    />
    <DeviceList
        :curDevices="curDevices"
        @onClose="onClose"
        @onSelectDeviceOk="data => onSelectDeviceOk(data)"
    />
    <Exception
        :curException="curException"
        :excTypeOptions="excTypeOptions"
        @onClose="onClose"
        @onChange="data => onChangeException(data)"
    />
    <a-drawer
        v-model:visible="visible"
        :closable="false"
        :maskClosable="false"
        title="合并报工"
        :destroyOnClose="true"
        height="100%"
    >
        <template #extra>
            <a-space direction="horizontal" :size="8">
                <a-button @click="handleCancel">取消</a-button>
                <a-button type="primary" @click="handleSubmit">提交</a-button>
            </a-space>
        </template>
        <a-descriptions title="工单信息" :column="2">
            <a-descriptions-item label="工单号" :span="2">
                {{ workOrderInfo?.code }}
            </a-descriptions-item>
            <a-descriptions-item label="生产班组">
                {{ workOrderInfo?.produceTeamName }}
            </a-descriptions-item>
            <a-descriptions-item label="班组长">
                {{ workOrderInfo?.produceTeamLeaderName }}
            </a-descriptions-item>
            <a-descriptions-item label="产品名称">
                {{ workOrderInfo?.productName }}
            </a-descriptions-item>
            <a-descriptions-item label="产品编码">
                {{ workOrderInfo?.productCode }}
            </a-descriptions-item>
            <a-descriptions-item label="规格型号">
                {{ workOrderInfo?.spec }}
            </a-descriptions-item>
        </a-descriptions>
        <a-descriptions title="报工操作"></a-descriptions>
        <a-table
            :columns="columns"
            :data-source="tableData"
            bordered
            size="small"
            :pagination="false"
            :scroll="{ x: 1500 }"
        >
            <template #headerCell="{ column, record, index }">
                <tempalte v-if="column.dataIndex === 'workOrderStepId'">
                    <span class="require-star">*</span>
                    报工工序
                </tempalte>
                <tempalte v-if="column.dataIndex === 'schedule'">
                    <span class="require-star">*</span>
                    生产进度(%)
                </tempalte>
                <tempalte v-if="column.dataIndex === 'reportDate'">
                    <span class="require-star">*</span>
                    生产日期
                </tempalte>
                <tempalte v-if="column.dataIndex === 'productTime'">
                    <span class="require-star">*</span>
                    生产时长
                </tempalte>
                <tempalte v-if="column.dataIndex === 'guideSale'">
                    <span>指导售价({{ comUnit }}/不含税)</span>
                </tempalte>
            </template>
            <template #bodyCell="{ column, record, index }">
                <template v-if="column.dataIndex === 'index'">
                    {{ index + 1 }}
                </template>

                <template v-if="column.dataIndex === 'attendances'">
                    <Select
                        v-model:value="record.attendances"
                        :options="attendanceOptions"
                        labelKey="value"
                        valueKey="key"
                        mode="multiple"
                        :labelInValue="true"
                        style="width: 100%"
                    />
                </template>

                <template v-if="column.dataIndex === 'hourlyWorkerCount'">
                    <a-input-number
                        v-model:value="record.hourlyWorkerCount"
                        :min="0"
                        :precision="0"
                        style="width: 100%"
                    />
                </template>

                <template v-if="column.dataIndex === 'workOrderStepId'">
                    <div>
                        <Select
                            v-model:value="record.workOrderStepId"
                            :options="reportProcessOptinons"
                            :allowClear="false"
                            labelKey="workOrderStepName"
                            valueKey="workOrderStepId"
                            @select="value => onChangeStep(value, index)"
                            style="width: 100%"
                        />
                        <div
                            style="margin-top: 5px; font-size: 12px; display: flex"
                            v-show="record.workOrderStepId"
                        >
                            <span>
                                已报工
                                {{ curProcess(record.workOrderStepId)?.reportPassSumSchedule }} %
                            </span>
                            <span>
                                剩余
                                {{
                                    100 - curProcess(record.workOrderStepId)?.reportPassSumSchedule
                                }}
                                %
                            </span>
                        </div>
                    </div>
                </template>

                <template v-if="column.dataIndex === 'schedule'">
                    <a-input-number
                        v-model:value="record.schedule"
                        :min="0"
                        :max="100"
                        style="width: 100%"
                    />
                </template>

                <template v-if="column.dataIndex === 'reportDate'">
                    <a-date-picker
                        v-model:value="record.reportDate"
                        valueFormat="YYYY-MM-DD"
                        style="width: 100%"
                        :allowClear="false"
                    />
                </template>

                <template v-if="column.dataIndex === 'productTime'">
                    <a-time-range-picker
                        v-model:value="record.productTime"
                        format="HH:mm"
                        valueFormat="HH:mm"
                        :minuteStep="10"
                        :allowClear="false"
                    />
                </template>

                <template v-if="column.dataIndex === 'livePictureIds'">
                    <a-button type="link" size="small" @click="handleOpenLoadPic(index)">
                        点击上传 ({{ record?.livePictureIds?.length }}/12)
                    </a-button>
                </template>

                <template v-if="column.dataIndex === 'deviceCodes'">
                    <span>共{{ record.deviceCodes?.length }}台</span>
                    <a-button
                        type="link"
                        size="small"
                        @click="handleOpenDevices(index, record.deviceCodes)"
                    >
                        点击操作
                    </a-button>
                </template>

                <template v-if="column.dataIndex === 'exception'">
                    <span>{{ record?.curException?.exception ? "是" : "否" }}</span>
                    <a-button type="link" size="small" @click="handleOpenException(index)">
                        点击修改
                    </a-button>
                    <div style="color: #ff4d4f; font-size: 12px" v-show="!comCheckHours(record)">
                        异常时长不能大于生产时长
                    </div>
                </template>

                <template v-if="column.dataIndex === 'description'">
                    <a-input v-model:value="record.description" />
                </template>

                <template v-if="column.dataIndex === 'operate'">
                    <a-space direction="horizontal" :size="5">
                        <a @click="handleDelete(index)">删除</a>
                    </a-space>
                </template>
            </template>
        </a-table>
        <div class="addicon-wrapper">
            <a-space direction="horizontal" :size="5" @click="handleAddWorkOrder">
                <plus-circle-outlined size="20" />
                <span>添加</span>
            </a-space>
        </div>
    </a-drawer>
</template>
<script>
import { defineComponent, ref, watch, reactive, toRefs, computed, onMounted, nextTick } from "vue";
import { apiWorkOrderGetReportInfo, apiWorkOrderSaveBatchReport } from "@/api";
import { showMessage, confirmModal, getAutoCreateCode } from "@/utils/common";
import { PlusCircleOutlined } from "@ant-design/icons-vue";
import Select from "@/components/Select";
import moment from "moment";
import LoadPic from "./loadPic";
import DeviceList from "./deviceList";
import Exception from "./exception";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 60,
        fixed: "left",
    },
    {
        title: "生产人员",
        dataIndex: "attendances",
        width: 280,
    },
    {
        title: "小时工人数",
        dataIndex: "hourlyWorkerCount",
        width: 100,
    },
    {
        title: "报工工序",
        dataIndex: "workOrderStepId",
        width: 200,
    },
    {
        title: "生产进度",
        dataIndex: "schedule",
        width: 120,
    },
    {
        title: "生产日期",
        dataIndex: "reportDate",
        width: 150,
    },
    {
        title: "生产时长",
        dataIndex: "productTime",
        width: 220,
    },
    {
        title: "图片",
        dataIndex: "livePictureIds",
    },
    {
        title: "描述",
        dataIndex: "description",
    },
    {
        title: "设备使用信息",
        dataIndex: "deviceCodes",
        width: 180,
    },
    {
        title: "异常信息",
        dataIndex: "exception",
    },
    {
        title: "操作",
        dataIndex: "operate",
        fixed: "right",
        width: 100,
    },
];

export default defineComponent({
    props: ["batchWorkReportId", "batchIssueData"],
    emits: ["update:batchWorkReportId"],
    components: {
        PlusCircleOutlined,
        Select,
        LoadPic,
        DeviceList,
        Exception,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            tableData: [],
            orderInfo: null,
            workOrderInfo: null,
            curIndex: null,
            curPicIds: null,
            curDevices: null,
            curException: null,
            attendanceOptions: [],
            reportProcessOptinons: [],
            excTypeOptions: [],
            curProcess: computed(() => {
                return workOrderStepId =>
                    state.reportProcessOptinons.find(
                        item => item.workOrderStepId === workOrderStepId
                    );
            }),
            comCheckHours: computed(() => {
                return record => {
                    const {
                        productTime,
                        curException: { exception, exceptionWorkingHours },
                    } = record;
                    if (productTime?.length && exception) {
                        const [sTime, eTime] = productTime;
                        let totalMinutes = moment(eTime, "hh:mm").diff(
                            moment(sTime, "hh:mm"),
                            "minute"
                        );
                        if (exceptionWorkingHours * 60 > totalMinutes) {
                            return false;
                        }
                    }
                    return true;
                };
            }),
        });

        const getIndexData = async id => {
            if (!id) return;
            let res = await apiWorkOrderGetReportInfo(id);
            if (res.status === "SUCCESS") {
                let {
                    mesWorkOrderInfo,
                    memberUsernameAndDisplayNameCandidate,
                    workOrderStepCandidate,
                    excTypeCandidate,
                } = res.data;
                state.workOrderInfo = mesWorkOrderInfo;
                state.attendanceOptions = memberUsernameAndDisplayNameCandidate;
                state.reportProcessOptinons = workOrderStepCandidate;
                state.excTypeOptions = excTypeCandidate.map((item, index) => ({
                    label: item,
                    value: item,
                }));
            }
        };

        const handleAddWorkOrder = async () => {
            state.tableData.push({
                attendances: [],
                hourlyWorkerCount: null,
                livePictureIds: [],
                deviceCodes: [],
                curException: {
                    exception: false,
                    exceptionWorkingHours: "",
                    exceptionMatter: "",
                    excType: "",
                    exceptionDescription: "",
                    exceptionEventFileIds: [],
                    exceptionProcessStatus: null,
                    exceptionProcessingScheme: "",
                    exceptionEventHandleFileIds: [],
                },
            });
        };

        const handleCancel = () => {
            state.tableData = [];
            emit("update:batchWorkReportId");
        };

        const handleSubmit = async () => {
            if (state.tableData?.length == 0) return showMessage("info", "请添加报工数据");
            for (let item of state.tableData) {
                if (
                    item.productTime == null ||
                    item.workOrderStepId == null ||
                    item.schedule == null ||
                    item.reportDate == null
                ) {
                    return showMessage("info", "请完善表格数据，红色标注字段为必填项");
                }
                if (item.attendances?.length == 0 && item.hourlyWorkerCount == null) {
                    return showMessage("info", "请选择生产人员或小时工人数");
                }
                if (!state.comCheckHours(item)) {
                    return showMessage("info", "异常时长不能大于生产时长");
                }
            }
            const workOrderStepIds = [
                ...new Set(state.tableData.map(item => item.workOrderStepId)),
            ];
            for (let item of workOrderStepIds) {
                const itemHasReport = state.reportProcessOptinons.find(
                    step => step.workOrderStepId == item
                )?.reportPassSumSchedule;
                const total = state.tableData
                    .filter(uu => uu.workOrderStepId == item)
                    .reduce((t, l) => t + l.schedule, 0);
                if (total + itemHasReport > 100)
                    return showMessage("info", "生产进度超出可报工范围");
            }
            const reaData = state.tableData.map(item => ({
                ...item.curException,
                ...item,
                deviceCodes: item.deviceCodes.map(uu => uu.code),
                attendances: item.attendances.map(uu => ({
                    username: uu?.option?.value,
                    displayName: uu?.option?.label,
                })),
                productionStartTime: `${item.reportDate} ${item.productTime[0]}:00`,
                productionEndTime: `${item.reportDate} ${item.productTime[1]}:00`,
            }));
            let res = await apiWorkOrderSaveBatchReport(reaData);
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                handleCancel();
                emit("onOk");
            }
        };

        const onChangeStep = (value, index) => {
            state.tableData[index].deviceCodes = state.curProcess(value)?.deviceCandidate;
        };

        const handleOpenLoadPic = index => {
            state.curIndex = index;
            state.curPicIds = state.tableData[index]?.livePictureIds;
        };

        const uploadPicSuccess = ids => {
            state.tableData[state.curIndex].livePictureIds = ids;
            state.curPicIds = ids;
        };

        const onClose = () => {
            state.curIndex = null;
            state.curPicIds = null;
            state.curDevices = null;
            state.curException = null;
        };

        const handleOpenDevices = index => {
            const { workOrderStepId, deviceCodes } = state.tableData[index];
            if (!workOrderStepId) return showMessage("info", "请先选择工序");
            state.curIndex = index;
            state.curDevices = deviceCodes.map((item, index) => ({
                ...item,
                index,
            }));
        };

        const onSelectDeviceOk = data => {
            state.tableData[state.curIndex].deviceCodes = data;
        };

        const handleDelete = index => {
            state.tableData.splice(index, 1);
        };

        const handleOpenException = index => {
            state.curIndex = index;
            const { productTime, attendances, hourlyWorkerCount, curException } =
                state.tableData[index];
            if ((!hourlyWorkerCount && !attendances?.length) || !productTime) {
                return showMessage("info", "请先选择生产人员或输入小时工人数，选择生产时长");
            }
            state.curException = {
                ...curException,
                productTime,
                attendances,
                hourlyWorkerCount,
            };
        };

        const onChangeException = data => {
            state.tableData[state.curIndex].curException = data;
        };

        watch(
            () => props.batchWorkReportId,
            newVal => {
                state.visible = Boolean(newVal);
                if (newVal) {
                    getIndexData(newVal);
                }
            }
        );

        return {
            ...toRefs(state),
            columns,
            handleCancel,
            handleSubmit,
            handleAddWorkOrder,
            handleDelete,
            onChangeStep,
            handleOpenLoadPic,
            uploadPicSuccess,
            onClose,
            handleOpenDevices,
            onSelectDeviceOk,
            handleOpenException,
            onChangeException,
        };
    },
});
</script>

<style lang="less" scoped>
.title {
    margin-bottom: 10px;
}
.operate-content {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.addicon-wrapper {
    margin: 16px 0;
    text-align: center;
    .ant-space {
        cursor: pointer;
    }
}
.no-enter-tips {
    color: red;
}
.require-star {
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
}
:deep(.ant-table-cell) {
    vertical-align: top;
    padding-top: 13px !important;
}
</style>
