<template>
    <a-descriptions title="">
        <a-descriptions-item label="工单号">
            {{ baseInfo?.code }}
        </a-descriptions-item>
        <a-descriptions-item label="工单状态">
            <span :style="{ color: getStatusColor(baseInfo?.status) }">
                {{ baseInfo?.statusDesc }}
            </span>
        </a-descriptions-item>
        <a-descriptions-item label="创建人">
            {{ baseInfo?.creatorDisplayName }}
        </a-descriptions-item>
        <a-descriptions-item label="生产订单号">
            {{ baseInfo?.orderCode || "未关联订单" }}
        </a-descriptions-item>
        <a-descriptions-item label="项目名称">
            {{ baseInfo?.projectName }}
        </a-descriptions-item>
        <a-descriptions-item label="项目编码">
            {{ baseInfo?.projectCode }}
        </a-descriptions-item>
        <a-descriptions-item label="产品名称">
            {{ baseInfo?.productName }}
        </a-descriptions-item>
        <a-descriptions-item label="产品编码">
            {{ baseInfo?.productCode }}
        </a-descriptions-item>
        <a-descriptions-item label="产成品追溯码">
            {{ baseInfo?.mesProductTraceCode }}
        </a-descriptions-item>
        <a-descriptions-item label="集成编号">
            {{ baseInfo?.containerNo }}
        </a-descriptions-item>
        <a-descriptions-item label="规格型号">
            {{ baseInfo?.spec }}
        </a-descriptions-item>
        <a-descriptions-item label="生产班组">
            {{ baseInfo?.produceTeamName }}
        </a-descriptions-item>
        <a-descriptions-item label="班组长">
            {{ baseInfo?.produceTeamLeaderName }}
        </a-descriptions-item>
        <a-descriptions-item label="计划投产日期">
            {{ baseInfo?.scheduledStartDate }}
        </a-descriptions-item>
        <a-descriptions-item label="计划完工日期">
            {{ baseInfo?.scheduledEndDate }}
        </a-descriptions-item>
        <a-descriptions-item label="实际投产日期">
            {{ baseInfo?.actualStartDate }}
        </a-descriptions-item>
        <a-descriptions-item label="工艺路线">
            {{ baseInfo?.processName }}
        </a-descriptions-item>
        <a-descriptions-item label="当前生产进度">
            <a-progress style="width: 200px" :percent="baseInfo?.progressPercent" size="small" />
        </a-descriptions-item>
    </a-descriptions>
</template>

<script>
import { defineComponent } from "vue";
import { getStatusColor } from "@/utils/common";
export default defineComponent({
    props: {
        baseInfo: {
            default: null,
        },
    },

    setup() {
        return {
            getStatusColor,
        };
    },
});
</script>
