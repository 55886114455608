<template>
    <UserList
        v-model:userListVisible="userListVisible"
        selectType="radio"
        @onSelect="onSelectCheckUser"
    />
    <a-modal
        v-model:visible="visible"
        title="工序完成"
        @cancel="handleCancel"
        :footer="null"
        :destroyOnClose="true"
        width="750px"
    >
        <a-form
            ref="formRef"
            :model="formData"
            name="basic"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 17 }"
            autocomplete="off"
            @finish="onFinish"
        >
            <a-form-item label="完成工序" name="workOrderStepId">
                <a-input :value="completeRow?.name" disabled />
                <!-- <div class="report-process-desc">
                    <div>
                        <span>数量要求：</span>
                        <span>
                            {{ isQuantify ? "定量" : "定性" }} /
                            {{ completeRow?.workReportPassedSchedule }}
                            {{ completeRow?.unit }}
                        </span>
                    </div>
                    <div>
                        <span>已完成：</span>
                        <span>
                            {{ completeRow?.workReportPassedSchedule }}
                            {{ completeRow?.unit }}
                        </span>
                    </div>
                </div> -->
            </a-form-item>

            <a-form-item label="质检要求" name="schedule">
                <a-input :value="completeRow?.needDetect ? '是' : '否'" disabled />
            </a-form-item>

            <a-form-item label="操作人员" name="userName">
                <a-input :value="userName" disabled />
            </a-form-item>

            <a-form-item
                name="livePictureIds"
                :rules="[{ required: true, message: '请上传现场图片' }]"
            >
                <template v-slot:label>现场图片({{ formData.livePictureIds.length }}/12)</template>
                <Upload
                    businessType="work_order_step_complete_live_pic"
                    @onSuccess="ids => (formData.livePictureIds = ids)"
                />
            </a-form-item>

            <div v-show="isNeedDetect">
                <a-form-item
                    label="检验结果"
                    name="checkPass"
                    :rules="[{ required: isNeedDetect, message: '请选择检验结果' }]"
                >
                    <a-radio-group v-model:value="formData.checkPass">
                        <a-radio :value="true">通过</a-radio>
                        <a-radio :value="false">不通过</a-radio>
                    </a-radio-group>
                </a-form-item>

                <a-form-item
                    label="检验员"
                    name="checkerDisplayName"
                    :rules="[{ required: isNeedDetect, message: '请选择检验员' }]"
                >
                    <div class="contant-item">
                        <a-input style="width: 320px" v-model:value="formData.checkerDisplayName">
                            <template #suffix>
                                <down-outlined style="color: rgba(0, 0, 0, 0.25)" />
                            </template>
                        </a-input>
                        <div class="mask" @click="userListVisible = true"></div>
                    </div>
                </a-form-item>

                <!-- <a-form-item
                    label="处理方式"
                    name="checkNotPassHandleType"
                    :rules="[
                        {
                            required: isNeedDetect && !formData.checkPass,
                            message: '请选择处理方式',
                        },
                    ]"
                    v-show="!formData.checkPass"
                >
                    <Select
                        v-model:value="formData.checkNotPassHandleType"
                        :options="[
                            { label: '让步接受', value: 'CONCESSIONS_ACCEPTED' },
                            { label: '工序返工', value: 'STEP_REWORK' },
                        ]"
                    />
                </a-form-item> -->

                <a-form-item
                    label="描述"
                    name="checkResult"
                    :rules="[{ validator: checkSpaceStartEnd }]"
                >
                    <a-textarea
                        v-model:value="formData.checkResult"
                        placeholder="最多可输入800字"
                        :rows="4"
                        :maxlength="800"
                    />
                </a-form-item>

                <a-form-item
                    label="检验员签名"
                    name="checkerSignaturePictureId"
                    :rules="[{ required: isNeedDetect, message: '请上传签名' }]"
                >
                    <Upload
                        :maxCount="1"
                        businessType="work_order_step_complete_sign_pic"
                        @onSuccess="ids => (formData.checkerSignaturePictureId = ids[0])"
                    />
                </a-form-item>
            </div>

            <a-form-item :wrapper-col="{ offset: 18, span: 5 }">
                <a-button style="margin-right: 8px" @click="handleCancel">取消</a-button>
                <a-button type="primary" html-type="submit">提交</a-button>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs, computed } from "vue";
import { apiWorkOrderProcessInfo, apiWorkOrderSaveProcessComplete } from "@/api";
import Select from "@/components/Select";
import { showMessage, checkValueIsAvailable } from "@/utils/common";
import { DownOutlined } from "@ant-design/icons-vue";
import Upload from "@/components/Upload";
import UserList from "@/components/UserList";
import { useStore } from "vuex";
export default defineComponent({
    props: ["completeRow"],
    emits: ["eventUpdateOk", "update:completeRow"],
    components: {
        Select,
        DownOutlined,
        Upload,
        UserList,
    },
    setup(props, { emit }) {
        const { getters } = useStore();
        const state = reactive({
            formRef: null,
            visible: false,
            userListVisible: false,
            formData: {
                workOrderStepId: "",
                livePictureIds: [],
                checkPass: "",
                checkerUsername: "",
                checkerDisplayName: "",
                checkResult: "",
                checkerSignaturePictureId: "",
            },
            isNeedDetect: computed(() => props.completeRow?.needDetect),
            isQuantify: computed(() => props.completeRow?.recordWorkType == "QUANTIFY"),
            userName: getters["user/userInfo"]?.username,
        });

        const handleCancel = () => {
            state.visible = false;
            state.formRef.resetFields();
            emit("update:completeRow", null);
        };

        const onSelectCheckUser = ([user]) => {
            state.formData.checkerUsername = user?.username;
            state.formData.checkerDisplayName = user?.displayName;
        };

        const onFinish = async () => {
            let res = await apiWorkOrderSaveProcessComplete(state.formData);
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                handleCancel();
                emit("eventUpdateOk");
            }
        };

        const checkSpaceStartEnd = (rule, value) => {
            if (value && !checkValueIsAvailable(value, "spaceStartEnd")) {
                return Promise.reject("不能以空格开头或结尾");
            } else {
                return Promise.resolve();
            }
        };

        watch(
            () => props.completeRow,
            newVal => {
                if (newVal !== null) {
                    state.visible = true;
                    state.formData.workOrderStepId = newVal?.id;
                } else {
                    state.visible = false;
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            onFinish,
            onSelectCheckUser,
            checkSpaceStartEnd,
        };
    },
});
</script>

<style lang="less" scoped>
.report-process-desc {
    display: flex;
    color: grey;
    margin-top: 10px;
    > div {
        flex: 1;
    }
}

.contant-item {
    display: flex;
    justify-content: space-between;
}
.mask {
    position: absolute;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 32px;
    height: 32px;
    border-radius: 2px;
    width: 320px;
    cursor: pointer;
    position: absolute;
    opacity: 0;
}
</style>
