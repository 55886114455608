<template>
    <DeviceSelect
        v-model:deviceSelectVisible="deviceSelectVisible"
        :disableData="deviceList"
        @onSelect="onSelectDevice"
    />
    <a-modal
        v-model:visible="visible"
        title="生产报工"
        @cancel="handleCancel"
        :footer="null"
        :destroyOnClose="true"
        width="850px"
    >
        <a-descriptions title="工单信息" :column="2">
            <a-descriptions-item label="工单号" :span="2">
                {{ workOrderInfo?.code }}
            </a-descriptions-item>
            <a-descriptions-item label="生产班组">
                {{ workOrderInfo?.produceTeamName }}
            </a-descriptions-item>
            <a-descriptions-item label="班组长">
                {{ workOrderInfo?.produceTeamLeaderName }}
            </a-descriptions-item>
            <a-descriptions-item label="产品名称">
                {{ workOrderInfo?.productName }}
            </a-descriptions-item>
            <a-descriptions-item label="产品编码">
                {{ workOrderInfo?.productCode }}
            </a-descriptions-item>
            <a-descriptions-item label="规格型号">
                {{ workOrderInfo?.spec }}
            </a-descriptions-item>
        </a-descriptions>

        <a-descriptions title="报工操作"></a-descriptions>

        <a-form
            ref="formRef"
            :model="formData"
            name="basic"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 17 }"
            :validateTrigger="['onBlur', 'change']"
            autocomplete="off"
            @finish="onFinish"
        >
            <a-tabs v-model:activeKey="activeKey">
                <a-tab-pane :key="1" tab="综合信息">
                    <a-form-item label="生产人员" name="attendances">
                        <Select
                            v-model:value="formData.attendances"
                            :options="attendanceOptions"
                            labelKey="value"
                            valueKey="key"
                            mode="multiple"
                            :labelInValue="true"
                        />
                    </a-form-item>

                    <a-form-item label="小时工人数" name="hourlyWorkerCount">
                        <a-input-number
                            v-model:value="formData.hourlyWorkerCount"
                            :min="0"
                            :precision="0"
                            style="width: 100%"
                        />
                    </a-form-item>

                    <a-form-item
                        label="报工工序"
                        name="workOrderStepId"
                        :rules="[{ required: true, message: '请选择报工工序' }]"
                    >
                        <Select
                            v-model:value="formData.workOrderStepId"
                            :options="reportProcessOptinons"
                            :allowClear="false"
                            labelKey="workOrderStepName"
                            valueKey="workOrderStepId"
                            @select="onChangeStep"
                        />
                    </a-form-item>

                    <a-form-item
                        label="生产进度"
                        name="schedule"
                        :rules="[{ required: true, message: '请输入生产进度' }]"
                    >
                        <a-input-number
                            v-model:value="formData.schedule"
                            :min="0"
                            :max="comMaxSchedule"
                            addon-after="%"
                            style="width: 100%"
                        />
                        <div class="report-process-desc" v-show="formData.workOrderStepId">
                            <div>
                                <div>
                                    <span>当前进度：</span>
                                    <span>已报工</span>
                                    <span>{{ curSelectProcess?.reportPassSumSchedule }} %</span>
                                </div>
                                <div>
                                    <span>剩余</span>
                                    <span>
                                        {{ 100 - curSelectProcess?.reportPassSumSchedule }} %
                                    </span>
                                </div>
                            </div>
                        </div>
                    </a-form-item>

                    <a-form-item
                        label="生产日期"
                        name="reportDate"
                        :rules="[{ required: true, message: '请选择生产日期' }]"
                    >
                        <a-date-picker
                            v-model:value="formData.reportDate"
                            valueFormat="YYYY-MM-DD"
                        />
                    </a-form-item>

                    <a-form-item
                        label="生产时长"
                        name="productTime"
                        :rules="[
                            { required: true, message: '请选择生产时长' },
                            { validator: checkProductTime },
                        ]"
                    >
                        <a-time-range-picker
                            v-model:value="formData.productTime"
                            format="HH:mm"
                            valueFormat="HH:mm"
                            :minuteStep="10"
                        />
                        <span style="margin-left: 8px" v-show="formData.productTime?.length">
                            {{ caluHours() }} / H
                        </span>
                    </a-form-item>

                    <a-form-item label="生产总工时" name="productTime">
                        <span style="margin-left: 8px">
                            {{
                                (
                                    caluHours() *
                                    (formData.attendances?.length + formData.hourlyWorkerCount)
                                ).toFixed(2)
                            }}
                            / H
                        </span>
                    </a-form-item>

                    <a-form-item
                        label="报工描述"
                        name="description"
                        :rules="[{ validator: checkSpaceAll }]"
                    >
                        <a-textarea
                            v-model:value="formData.description"
                            placeholder="最多可输入800字"
                            :rows="4"
                            :maxlength="800"
                        />
                    </a-form-item>

                    <a-form-item name="livePictureIds">
                        <template v-slot:label>
                            现场图片({{ formData.livePictureIds.length }}/12)
                        </template>
                        <Upload
                            businessType="REPORT_WORK_LIVE_PICTURE"
                            @onSuccess="ids => (formData.livePictureIds = ids)"
                        />
                    </a-form-item>
                </a-tab-pane>
                <a-tab-pane :key="2" tab="异常信息">
                    <a-form-item
                        label="异常情况"
                        name="exception"
                        :rules="[{ required: true, message: '请选择异常情况' }]"
                    >
                        <a-radio-group v-model:value="formData.exception">
                            <a-radio :value="true">是</a-radio>
                            <a-radio :value="false">否</a-radio>
                        </a-radio-group>
                    </a-form-item>

                    <div v-show="formData.exception">
                        <a-form-item
                            label="异常时长"
                            name="exceptionWorkingHours"
                            :rules="[
                                { required: formData.exception, message: '请输入异常时长' },
                                { validator: checkExceptionWorkingHours },
                            ]"
                        >
                            <a-input-number
                                v-model:value="formData.exceptionWorkingHours"
                                :disabled="!formData.exception"
                                :min="0"
                                :precision="2"
                                style="width: 100%"
                            />
                        </a-form-item>

                        <a-form-item label="异常总工时" name="productTime">
                            <span style="margin-left: 8px">
                                {{
                                    (
                                        formData.exceptionWorkingHours *
                                        (formData.attendances?.length + formData.hourlyWorkerCount)
                                    ).toFixed(2)
                                }}
                                / H
                            </span>
                        </a-form-item>

                        <a-form-item
                            label="异常事件名称"
                            name="exceptionMatter"
                            :rules="[
                                { required: formData.exception, message: '请输入异常事件名称' },
                                { validator: checkSpaceAll },
                            ]"
                        >
                            <a-input
                                v-model:value="formData.exceptionMatter"
                                :disabled="!formData.exception"
                                :maxlength="100"
                                showCount
                            />
                        </a-form-item>

                        <a-form-item
                            label="异常类型"
                            name="excType"
                            :rules="[{ required: formData.exception, message: '请选择异常类型' }]"
                        >
                            <Select v-model:value="formData.excType" :options="excTypeOptions" />
                        </a-form-item>

                        <a-form-item
                            label="异常描述"
                            name="exceptionDescription"
                            :rules="[{ validator: checkSpaceAll }]"
                        >
                            <a-textarea
                                v-model:value="formData.exceptionDescription"
                                placeholder="最多可输入800字"
                                :disabled="!formData.exception"
                                :rows="4"
                                :maxlength="800"
                            />
                        </a-form-item>

                        <a-form-item name="exceptionEventFileIds">
                            <template v-slot:label>
                                事件图片({{ formData.exceptionEventFileIds.length }}/12)
                            </template>
                            <Upload
                                businessType="mes_exc_report_event_pic"
                                @onSuccess="ids => (formData.exceptionEventFileIds = ids)"
                            />
                        </a-form-item>

                        <a-form-item
                            label="处理状态"
                            name="exceptionProcessStatus"
                            :rules="[{ required: formData.exception, message: '请选择处理状态' }]"
                        >
                            <a-radio-group v-model:value="formData.exceptionProcessStatus">
                                <a-radio value="PROCESSED">已处理</a-radio>
                                <a-radio value="WAIT_PROCESSED">未处理</a-radio>
                            </a-radio-group>
                        </a-form-item>

                        <a-form-item
                            label="处理方案"
                            name="exceptionProcessingScheme"
                            v-show="comExceptionProcessStatus"
                            :rules="[
                                {
                                    required: comExceptionProcessStatus,
                                    message: '请输入处理方案',
                                },
                                { validator: checkSpaceAll },
                            ]"
                        >
                            <a-textarea
                                v-model:value="formData.exceptionProcessingScheme"
                                placeholder="最多可输入800字"
                                :disabled="!formData.exception"
                                :rows="4"
                                :maxlength="800"
                            />
                        </a-form-item>

                        <a-form-item
                            name="exceptionEventHandleFileIds"
                            v-show="comExceptionProcessStatus"
                        >
                            <template v-slot:label>
                                处理方案图片({{ formData.exceptionEventHandleFileIds.length }}/12)
                            </template>
                            <Upload
                                businessType="mes_exc_report_handle_pic"
                                @onSuccess="ids => (formData.exceptionEventHandleFileIds = ids)"
                            />
                        </a-form-item>
                    </div>
                </a-tab-pane>
                <a-tab-pane :key="3" tab="设备使用信息">
                    <div class="search">
                        <a-button type="primary" size="small" @click="handleAddDevice">
                            添加
                        </a-button>
                    </div>
                    <DeviceList :deviceList="deviceList" @onDeleteDevice="onDeleteDevice" />
                </a-tab-pane>
            </a-tabs>

            <a-form-item :wrapper-col="{ offset: 18, span: 5 }">
                <a-button style="margin-right: 8px" @click="handleCancel">取消</a-button>
                <a-button type="primary" html-type="submit">提交</a-button>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs, computed } from "vue";
import { apiWorkOrderGetReportInfo, apiWorkOrderSaveReport } from "@/api";
import Select from "@/components/Select";
import { showMessage, checkValueIsAvailable } from "@/utils/common";
import { DownOutlined } from "@ant-design/icons-vue";
import Upload from "@/components/Upload";
import DeviceList from "./deviceList";
import DeviceSelect from "@/components/DeviceList";
import moment from "moment";
export default defineComponent({
    props: ["workReportId"],
    emits: ["eventUpdateOk", "update:workReportId"],
    components: {
        Select,
        DownOutlined,
        Upload,
        DeviceList,
        DeviceSelect,
    },
    setup(props, { emit }) {
        const state = reactive({
            formRef: null,
            visible: false,
            activeKey: 1,
            workOrderInfo: null,
            attendanceOptions: [],
            reportProcessOptinons: [],
            excTypeOptions: [],
            orderListModalVisible: false,
            deviceSelectVisible: false,
            deviceList: [],
            formData: {
                attendances: [],
                hourlyWorkerCount: null,
                workOrderStepId: "",
                schedule: "",
                reportDate: "",
                productTime: [],
                description: "",
                livePictureIds: [],
                exception: false,
                exceptionWorkingHours: "",
                exceptionMatter: "",
                excType: "",
                exceptionDescription: "",
                exceptionEventFileIds: [],
                exceptionProcessStatus: null,
                exceptionProcessingScheme: "",
                exceptionEventHandleFileIds: [],
            },
            curSelectProcess: computed(() => {
                return state.reportProcessOptinons.find(
                    item => item.workOrderStepId === state.formData.workOrderStepId
                );
            }),
            comMaxSchedule: computed(() => 100 - state.curSelectProcess?.reportPassSumSchedule),
            comExceptionProcessStatus: computed(
                () => state.formData.exceptionProcessStatus == "PROCESSED"
            ),
        });

        const handleCancel = () => {
            state.visible = false;
            state.formRef.resetFields();
            state.formData.productTime = [];
            state.deviceList = [];
            state.activeKey = 1;
            emit("update:workReportId", null);
        };

        const onFinish = async () => {
            let {
                reportDate,
                productTime: [productionStartTime, productionEndTime],
                attendances,
                hourlyWorkerCount,
            } = state.formData;
            let reqData = {
                ...state.formData,
                attendances: attendances.map(item => ({
                    username: item?.option?.value,
                    displayName: item?.option?.label,
                })),
                productionStartTime: `${reportDate} ${productionStartTime}:00`,
                productionEndTime: `${reportDate} ${productionEndTime}:00`,
                deviceCodes: state.deviceList?.map(uu => uu.code),
            };
            if (attendances?.length == 0 && hourlyWorkerCount == null) {
                return showMessage("info", "请选择生产人员或小时工人数");
            }
            if (!reqData.exception) {
                reqData.exceptionWorkingHours = "";
                reqData.exceptionMatter = "";
                reqData.exceptionLevel = null;
                reqData.exceptionDescription = "";
                reqData.exceptionEventFileIds = [];
                reqData.exceptionProcessStatus = null;
                reqData.exceptionProcessingScheme = "";
                reqData.exceptionEventHandleFileIds = [];
            }
            let res = await apiWorkOrderSaveReport(reqData);
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                handleCancel();
                emit("eventUpdateOk");
            }
        };

        const getIndexData = async id => {
            let res = await apiWorkOrderGetReportInfo(id);
            if (res.status === "SUCCESS") {
                let {
                    mesWorkOrderInfo,
                    memberUsernameAndDisplayNameCandidate,
                    workOrderStepCandidate,
                    excTypeCandidate,
                } = res.data;
                state.workOrderInfo = mesWorkOrderInfo;
                state.attendanceOptions = memberUsernameAndDisplayNameCandidate;
                state.reportProcessOptinons = workOrderStepCandidate;
                state.excTypeOptions = excTypeCandidate.map((item, index) => ({
                    label: item,
                    value: item,
                }));
            }
        };

        const checkOrder = (rule, value) => {
            if (!value && !state.formData.relatedOrders) {
                return Promise.reject("请选择生产订单号");
            } else {
                return Promise.resolve();
            }
        };

        const checkExceptionWorkingHours = (rule, value) => {
            const { productTime, exception } = state.formData;
            if (productTime.length && exception) {
                const [sTime, eTime] = productTime;
                let totalMinutes = moment(eTime, "hh:mm").diff(moment(sTime, "hh:mm"), "minute");
                let exceptionHours = value * 60;
                if (exceptionHours > totalMinutes) {
                    return Promise.reject("异常时长不能大于生产时长");
                }
            }
            return Promise.resolve();
        };

        const caluHours = () => {
            const { productTime } = state.formData;
            if (productTime?.length == 2) {
                const [sTime, eTime] = productTime;
                const during = moment(eTime, "hh:mm").diff(moment(sTime, "hh:mm"), "minute") / 60;
                return (Math.floor(during * 10) / 10).toFixed(2);
            }
            return "";
        };

        const checkSpaceAll = (rule, value) => {
            if (value && checkValueIsAvailable(value, "spaceAll")) {
                return Promise.reject("不能全是空格");
            } else {
                return Promise.resolve();
            }
        };

        const checkProductTime = (rule, value) => {
            if (value && value?.length) {
                if (value[0] == value[1]) return Promise.reject("生产时长不能为0");
            }
            return Promise.resolve();
        };

        const onChangeStep = id => {
            const devices = state.reportProcessOptinons.find(
                item => item.workOrderStepId == id
            )?.deviceCandidate;
            state.deviceList = [...devices].map((item, index) => ({
                ...item,
                key: index + 1,
                index,
            }));
        };

        const handleAddDevice = () => {
            if (!state.formData.workOrderStepId) return showMessage("info", "请先选择工序");
            state.deviceSelectVisible = true;
        };

        const onDeleteDevice = index => {
            state.deviceList.splice(index, 1);
            state.deviceList = state.deviceList.map((item, index) => ({
                ...item,
                key: index + 1,
                index,
            }));
        };

        const onSelectDevice = value => {
            state.deviceList = [...state.deviceList, ...value].map((item, index) => ({
                ...item,
                key: index + 1,
                index,
            }));
        };

        watch(
            () => state.curSelectProcess,
            newVal => {
                if (newVal?.canReportSumSchedule == 0) {
                    showMessage("warning", "该工序已报工完成，不能再继续报工");
                }
            }
        );

        watch(
            () => props.workReportId,
            newVal => {
                if (newVal !== null) {
                    state.visible = true;
                    getIndexData(newVal);
                } else {
                    state.visible = false;
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            onFinish,
            checkSpaceAll,
            checkOrder,
            checkExceptionWorkingHours,
            caluHours,
            checkSpaceAll,
            checkProductTime,
            onSelectDevice,
            onChangeStep,
            onDeleteDevice,
            handleAddDevice,
        };
    },
});
</script>

<style lang="less" scoped>
.report-process-desc {
    color: grey;
    > div {
        margin-top: 16px;
        display: flex;
        > div {
            flex: 1;
        }
    }
}
.search {
    text-align: right;
    margin-bottom: 10px;
}
</style>
