<template>
    <Rework v-model:reworkRow="reworkRow" @eventUpdateOk="getInfoData" />
    <CompleteWork v-model:completeRow="completeRow" @eventUpdateOk="getInfoData" />
    <a-drawer
        v-model:visible="visible"
        :closable="false"
        :maskClosable="false"
        title="工序流转"
        :destroyOnClose="true"
        height="100%"
    >
        <template #extra>
            <a-space direction="horizontal" :size="8">
                <a-button @click="handleCancel">取消</a-button>
                <a-button type="primary" @click="handleCancel">关闭</a-button>
            </a-space>
        </template>
        <a-descriptions title="工单信息">
            <a-descriptions-item label="工单号">
                {{ infoData?.code }}
            </a-descriptions-item>
            <a-descriptions-item label="工单状态">
                <span :style="{ color: getStatusColor(infoData?.status) }">
                    {{ infoData?.statusDesc }}
                </span>
            </a-descriptions-item>
            <a-descriptions-item label="产品名称">
                {{ infoData?.productName }}
            </a-descriptions-item>
            <a-descriptions-item label="产品编码">
                {{ infoData?.productCode }}
            </a-descriptions-item>
            <a-descriptions-item label="规格型号">{{ infoData?.spec }}</a-descriptions-item>
            <a-descriptions-item label="生产班组">
                {{ infoData?.produceTeamName }}
            </a-descriptions-item>
            <a-descriptions-item label="班组长">
                {{ infoData?.produceTeamLeaderName }}
            </a-descriptions-item>
        </a-descriptions>
        <div class="desc"></div>
        <a-descriptions title="工序列表"></a-descriptions>
        <a-table
            :columns="columns"
            :data-source="infoData?.steps"
            bordered
            size="small"
            :scroll="{ x: 1500 }"
        >
            <template #bodyCell="{ column, record, index }">
                <template v-if="column.dataIndex === 'index'">
                    {{ index + 1 }}
                </template>
                <template v-if="column.dataIndex === 'needDetect'">
                    {{ record.needDetect ? "是" : "否" }}
                </template>
                <template v-if="column.dataIndex === 'statusDesc'">
                    <span :style="{ color: getStatusColor(record?.status) }">
                        {{ record.statusDesc }}
                    </span>
                </template>
                <template v-if="column.dataIndex === 'sumReportDurationHours'">
                    {{ record?.sumReportDurationHours }} 
                </template>
                <template v-if="column.dataIndex === 'workReportPassedSchedule'">
                    {{ record?.workReportPassedSchedule }} %
                </template>
                <template v-if="column.dataIndex === 'operate'">
                    <a v-if="record.status == 'COMPLETE'" @click="() => (reworkRow = record)">
                        返工
                    </a>
                </template>
            </template>
        </a-table>
    </a-drawer>
</template>
<script>
import { defineComponent, ref, watch, reactive, toRefs, computed, onMounted, nextTick } from "vue";
import { apiProductWorkOrderDetail, apiProductOrderIssue } from "@/api";
import { showMessage } from "@/utils/common";
import { PlusCircleOutlined } from "@ant-design/icons-vue";
import Select from "@/components/Select";
import Rework from "./rework";
import CompleteWork from "./completeWork";
import { getStatusColor } from "@/utils/common";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 60,
        fixed: "left",
    },
    {
        title: "工序编码",
        dataIndex: "code",
    },
    {
        title: "工序名称",
        dataIndex: "name",
    },
    {
        title: "工艺要求",
        dataIndex: "requirements",
    },
    {
        title: "检验要求",
        dataIndex: "needDetect",
    },
    {
        title: "完成状态",
        dataIndex: "workReportPassedSchedule",
    },
    {
        title: "质检状态",
        dataIndex: "checkPass",
    },
    {
        title: "返工次数",
        dataIndex: "reworkCount",
    },
    {
        title: "消耗工时",
        dataIndex: "sumReportDurationHours",
    },
    {
        title: "最终完成日期",
        dataIndex: "completeTime",
    },
    {
        title: "操作",
        dataIndex: "operate",
        fixed: "right",
        width: 100,
    },
];

export default defineComponent({
    props: ["processflowId"],
    emits: ["onEmitOk", "update:processflowId"],
    components: {
        PlusCircleOutlined,
        Select,
        CompleteWork,
        Rework,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            tableData: [],
            infoData: null,
            reworkRow: null,
            completeRow: null,
            comRework: computed(() => {
                return record => {
                    if (record.needDetect) {
                        if (record.checkPass) return true;
                    } else if (record?.workReportPassedSchedule == 100) {
                        return true;
                    }
                    return false;
                };
            }),
        });

        const getInfoData = async () => {
            let res = await apiProductWorkOrderDetail(props.processflowId);
            if (res.status === "SUCCESS") {
                state.infoData = res.data;
            }
        };

        const handleCancel = () => {
            emit("update:processflowId");
            emit("onEmitOk");
        };

        watch(
            () => props.processflowId,
            newVal => {
                state.visible = Boolean(newVal);
                if (newVal) getInfoData();
            }
        );

        return {
            columns,
            ...toRefs(state),
            handleCancel,
            getInfoData,
            getStatusColor,
        };
    },
});
</script>
