<template>
    <a-modal
        v-model:visible="visible"
        title="设备列表"
        width="1000px"
        okText="确定"
        :destroyOnClose="true"
        @cancel="handleCancel"
        @ok="handleSubmit"
    >
        <a-space class="serach-space" direction="horizontal" :size="8">
            <a-input v-model:value="params.code" placeholder="请输入设备编码" />
            <a-input v-model:value="params.name" placeholder="请输入设备名称" />
            <a-button type="primary" @click="getTabelData">搜索</a-button>
            <a-button type="primary" @click="reset" danger>重置</a-button>
        </a-space>

        <a-table
            :columns="columns"
            :data-source="tableData"
            :loading="loading"
            bordered
            size="small"
            :pagination="pagination"
            @change="paginationChange"
            :row-selection="{
                selectedRowKeys,
                onChange: onSelect,
                type: 'checkbox',
                getCheckboxProps: record => ({
                    disabled: disableData.map(item => item.id).includes(record.id),
                }),
            }"
        >
            <template #bodyCell="{ column, record, index }">
                <template v-if="column.dataIndex === 'index'">
                    {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
                </template>
            </template>
        </a-table>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs } from "vue";
import { apiDeviceList } from "@/api";
import { showMessage } from "@/utils/common";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 60,
        fixed: "left",
    },
    {
        title: "设备编码",
        dataIndex: "code",
    },
    {
        title: "设备名称",
        dataIndex: "name",
    },
    {
        title: "设备状态",
        dataIndex: "statusZh",
    },
    {
        title: "设备类别",
        dataIndex: "categoryName",
    },
    {
        title: "规格型号",
        dataIndex: "spec",
    },
    {
        title: "品牌",
        dataIndex: "brand",
    },
    {
        title: "出厂编号",
        dataIndex: "factoryNumber",
    },
];

export default defineComponent({
    props: {
        deviceSelectVisible: {
            default: false,
        },
        disableData: {
            default: [],
        },
    },
    emits: ["update:deviceSelectVisible", "onSelect"],
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            loading: false,
            tableData: [],
            selectedRowKeys: [],
            selectedRows: [],
            params: {
                code: "",
                name: "",
            },
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
            },
        });

        const handleCancel = () => {
            state.pagination.current = 1;
            state.visible = false;
            state.selectedRowKeys = [];
            state.selectedRows = [];
            emit("update:deviceSelectVisible", false);
        };

        const reset = () => {
            state.params.code = undefined;
            state.params.name = undefined;
            getTabelData();
        };

        const getTabelData = async () => {
            state.loading = true;
            const {
                pagination: { current: pageNum, pageSize },
                params: { code, name },
            } = state;
            const data = {
                pageNum,
                pageSize,
                queryParam: {
                    code: code?.trim(),
                    name: name?.trim(),
                    enabled: true,
                },
            };
            let res = await apiDeviceList(data);
            state.loading = false;
            if (res.status === "SUCCESS") {
                state.pagination.total = res.data?.totalRecord;
                state.tableData = res.data.recordList.map((item, index) => ({
                    ...item,
                    key: index,
                }));
            }
            // 可选择列表出现+号是因为数据有children字段
        };

        const handleSearch = () => {
            state.pagination.current = 1;
            getTabelData();
        };

        const paginationChange = pagination => {
            state.selectedRowKeys = [];
            state.selectedRows = [];
            state.pagination = pagination;
            getTabelData();
        };

        const onSelect = (selectedRowKeys, selectedRows) => {
            state.selectedRowKeys = selectedRowKeys;
            state.selectedRows = selectedRows;
        };

        const handleSubmit = async () => {
            if (!state.selectedRows.length) {
                return showMessage("info", "请先选择设备");
            }
            emit("onSelect", state.selectedRows);
            handleCancel();
        };

        watch(
            () => props.deviceSelectVisible,
            newVal => {
                state.visible = Boolean(newVal);
                if (newVal) {
                    state.params.code = "";
                    state.params.name = "";
                    getTabelData();
                }
            }
        );

        return {
            columns,
            ...toRefs(state),
            handleCancel,
            paginationChange,
            getTabelData,
            handleSubmit,
            onSelect,
            handleSearch,
            reset,
        };
    },
});
</script>

<style lang="less" scoped>
:deep(.ant-switch-checked .ant-switch-handle) {
    left: calc(120% - 22px - 4px - 2px);
}
.serach-space {
    margin-right: 16px;
    margin-bottom: 16px;
    .ant-input,
    .ant-select {
        width: 160px;
    }
}
</style>
